<template>
  <div class="container" id="content">
    <div class="title">
      <div class="title-top">
        <div class="el-title-item">
          <el-form ref="form" label-width="80px">
            <el-form-item label="">
              <el-button type="success" size="small" @click="addOption">添加灾情预警</el-button>
            </el-form-item>
            <el-form-item label="" style="margin-left: 2vw">
              <el-input v-model="searchName" placeholder="请输入灾情名称"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <el-button style="margin-left: 1vw;" type="success" size="small" @click="search">搜索</el-button>
      </div>
    </div>
    <el-table
        v-loading="loading"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%; margin-top: 1vh;">
      <el-table-column prop="id" label="ID" align="center"></el-table-column>
      <el-table-column prop="disasterWarningName" label="灾情名称" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="releaseTime" label="发布时间" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="disasterLevel" label="灾情级别" show-overflow-tooltip align="center">
        <template slot-scope="scope">
          <span>{{scope.row.disasterLevel}}级</span>
        </template>
      </el-table-column>
      <el-table-column prop="defenseGuide" label="防御指南" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column label="操作" align="center" width="150">
        <template slot-scope="scope">
          <!-- <el-button
            type="primary"
            size="small"
            icon="el-icon-document"
            @click="details(scope.row)"
          ></el-button> -->
          <el-button
            type="primary"
            size="small"
            icon="el-icon-edit"
            @click="edits(scope.row)"
          ></el-button>
          <el-button
            type="primary"
            size="small"
            icon="el-icon-delete"
            @click="deletes(scope.row.id)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block pagingBox">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
      >
      </el-pagination>
    </div>
    <!--    编辑-->
    <el-dialog title="" :visible.sync="messageBox" width="31vw" class="addAlameBox">
      <div slot="title" class="header-title" style="position: relative">
        <span class="title-name"></span>
        <span class="title-age">{{ formTitle }}</span>
        <span style=" position: absolute; top: 50%; transform: translateY(-50%); right: 0px; font-size: 2.22vh;"></span>
      </div>
      <div class="addAlaForm">
        <el-form ref="addForm" :rules="rules" :model="addForm" label-width="10vw">
          <el-form-item label="灾情名称" prop="disasterWarningName">
            <el-input v-model="addForm.disasterWarningName" placeholder="请输入灾情名称"></el-input>
          </el-form-item>
          <el-form-item label="发布时间" prop="releaseTime">
            <el-date-picker v-model="addForm.releaseTime" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"> </el-date-picker>
         </el-form-item>
          <el-form-item label="灾情级别" prop="disasterLevel">
            <el-input type="number" v-model="addForm.disasterLevel" placeholder="请输入级别">
              <template slot="append">级</template>
            </el-input>
          </el-form-item>
          <el-form-item label="防御指南" prop="defenseGuide">
            <el-input v-model="addForm.defenseGuide" placeholder="请输入指南"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" v-show="showFlag">
          <el-button type="info" @click="messageBox = false">取消</el-button>
          <el-button type="primary" @click="saveForm('addForm')">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'sensorManagement',
  data() {
    return {
      tableData: [],
      page: 1,
      size: 10,
      total: 0,
      loading: false,
      ids: 0,
      searchName: '',
      addForm: {},
      rules: {
        disasterWarningName: [
          {required: true, message: "名称不可为空", trigger: ["blur", "change"]},
        ],
        // unit: [{required: true, message: "单位不可为空", trigger: ["blur", "change"]}],
        disasterLevel: [
          {required: true, message: "级别不可为空", trigger: ["blur", "change"]},
          {min: 1, max:6, message: '长度在 1 到 6 个字符', trigger: ['blur', 'change']}
        ],
        releaseTime: [
          {required: true, message: "时间不可为空", trigger: ["blur", "change"]},
        ],
        defenseGuide: [
          {required: true, message: "防御指南不可为空", trigger: ["blur", "change"]},
        ],
      },
      formTitle: "",
      messageBox: false,
      showFlag: false,
      value: true
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    //请求列表数据
    getList() {
      this.$get("/disasterWarning/getDisasterWarningList", {
        page: this.page,
        limit: this.size,
        name: this.searchName,

      }).then((res) => {
        if (res.data.state == "success") {
          this.tableData = res.data.datas;
          this.total = res.data.count;
          //console.log("获取列表", this.tableData)
        }
        setTimeout(() => {
          // this.loading = false;
        }, 500);
      });
    },
    // 编辑
    edits(row) {
      this.showFlag = true;
      this.ids = row.id;
      this.formTitle = "编辑触发器信息";
      this.messageBox = true;
      this.addForm = JSON.parse(JSON.stringify(row));
    },
    // 删除
    deletes(id) {
      // 弹出提示是否删除
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post("/disasterWarning/deleteDisasterWarningById", {
            id: id,
          }).then((res) => {
            if (res.data.state == "success") {
              this.$message.success("删除成功");
              this.getList();
            } else {
              this.$message.error("删除失败");
            }
          });
        })
        .catch((e) => e);
    },
    // 确认提交
    saveForm(formName) {
      // let id = this.ids;
      this.addForm.id = this.ids;
      let url = '/disasterWarning/addOrUpdateDisasterWarning';
      if(this.formTitle == '新增'){
        delete this.addForm.id;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$post(url, this.addForm
            // id: id,
            // disasterWarningName: this.addForm.disasterWarningName,
            // releaseTime: this.addForm.releaseTime,
            // disasterLevel: this.addForm.disasterLevel,
            // defenseGuide: this.addForm.defenseGuide,
          ).then((res) => {
            if (res.data.state == 'success') {
              if(this.formTitle == '新增'){
                this.$message.success('添加成功')
              }else{
                this.$message.success('修改成功')
              }    
              this.messageBox = false
              this.getList();
            } else {
              this.$message.error(res.data.msg)
              this.messageBox = false
            }
          })
        } else {
          //console.log('error submit!!');
          return false;
        }
      });
    },
    // 新增
    addOption() {
      this.formTitle = "新增";
      this.messageBox = true;
      this.showFlag = true;
      this.$nextTick(() => {
        this.addForm = {};
        this.$refs.addForm.resetFields();
      });
    },
    search() {
      //console.log(11111)
      this.page = 1
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
  },
};
</script>

<style scoped lang="less">
@import "../../../style/backTable.less";
</style>
<style scoped>
#content /deep/ .el-table th {
  background-color: rgba(240, 242, 245, 1) !important;
}

#content /deep/ .el-table {
  max-height: 78vh;
  overflow-y: scroll;
}

#content /deep/ .el-table::-webkit-scrollbar {
  display: none;
}

.addAlaForm /deep/ .el-select {
  width: 100% !important;
}

.dialog-footer {
  height: 5vh;
  text-align: right;
}

.dialog-footer /deep/ .el-button, .el-button--info {
  margin-left: 10px !important;

}

.dialog-footer /deep/ .el-button--info {
  background-color: transparent !important;
  color: #000;
}

.title /deep/ .el-input__inner {
  height: 33px !important;
}

.drawerBox /deep/ .msg {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1vh 1vw;
}

</style>


